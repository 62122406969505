import axios from 'utility/axios'
import {
	GET_USER,
	GET_USER_NOTIFICATIONS,
	GET_USER_PROPERTIES,
	POST_USER_PROFILE,
	POST_UPDATE_USER_NOTIFICATION
} from 'utility/endpoints'
import { formatPropertyMetaStatus, formatPropertySignupStatus } from 'utility/property'

import { formatUserObject } from 'utility/object'

export const fetchUser = async ({ slug }) => {
	const res = await axios.get(GET_USER + slug)
	const formattedUser = formatUserObject(res?.data)
	return formattedUser
}

export const updateUserProfile = ({ slugId, data, tenantCode = 'hometaxshield' }) => {
	if (!slugId) throw new Error('No slugId provided')
	return axios.post(POST_USER_PROFILE, {
		slugId,
		tenantCode,
		...data,
	})
}

export const fetchUserNotifications = async ({ userSlug }) => {
	let url = `${GET_USER_NOTIFICATIONS}${userSlug}`
	const res = await axios.get(url)
	return res?.data
}

export const postUpdateUserNotification = async ({
	notificationId,
	userSlugId,
	tenantCode = 'hometaxshield',
}) => {
	
	const res = await axios.post(POST_UPDATE_USER_NOTIFICATION, {
		notificationId,
		userSlugId,
		tenantCode,
	})

	return res?.data
}

export const fetchUserProperties = async ({ slug, year }) => {
	const url = GET_USER_PROPERTIES({
		userSlugId: slug,
		year,
	})

	const res = await axios.get(url)

	return {
		...res?.data,
		properties: res?.data?.properties?.map((property) => {
			return {
				id: property?.propertyTag,
				htsGroup: property,
				meta: {
					signupState: formatPropertySignupStatus({ data: property }),
					status: formatPropertyMetaStatus({ data: property }),
				},
			}
		}),
	}
}
